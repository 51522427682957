.signature{
  font-size: 150px;
  font-family: 'Comforter', cursive;
}
.sub-signature{
  font-size: 60px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.title-text{
  font-size: 30px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.row {
  justify-content: center;
  text-align: center;
}
.column {
  text-align: center;
}
.box{
  display: flex;
  height:100%;
  width:200%;
  background:linear-gradient(rgb(255, 255, 255),transparent);
  background-color:rgba(250, 77, 198, 0.5) /*this your primary color*/
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  border: 3px solid green;
}
.topbg{
  display: absolute;
  width: 100%;
  height: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
